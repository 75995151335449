import firebase from 'firebase'


// Firebase Configuration
const config = {
    apiKey: "AIzaSyDSIE2JDPz8WRGSP7ehqsIc5QMFh5JDr3Y",
    authDomain: "flikbox-2606.firebaseapp.com",
    projectId: "flikbox-2606",
    storageBucket: "flikbox-2606.appspot.com",
    messagingSenderId: "195515976423",
    appId: "1:195515976423:web:81a3bb84911f2d33183042",
    measurementId: "G-GG83DKG7QK"
};

// Firebase Initialization
//const firebaseApp = firebase.initializeApp(config);
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

// Defining Firestore Collections
const db = firebase.firestore();
const usersCollection = db.collection("users");
const userOrdersCollection = db.collection("userOrders");
const userInfoCollection = db.collection("userInfo");


// Function to signup a user with email and password
export const createUserWithEmailPassword = async (email, password) => {
    const status = await firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
        return userCredential;
    })
    .catch((error) => {
        return error
    });
    return status
}

// Firebase function to send account verification email
export const sendVerificationEmail = () => {
    var actionCodeSettings = {
        url: "https://flikbox-2606.web.app/auth-action/verify-email?uid=" + firebase.auth().currentUser.uid
    };
    firebase.auth().currentUser.sendEmailVerification(actionCodeSettings);
}

// Function to signup a user with Google Login
export const createUserWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    var res = await firebase.auth().signInWithPopup(provider)
    .then((result) => {
        return result;
    })
    .catch((error) => {
        return error
    });
    return res
}

// Function to login a user with email and password
export const loginUserWithEmailPassword = async (email, password) => {
    const status = await firebase.auth().signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
        return userCredential;
    })
    .catch((error) => {
        return error
    });
    return status
}

// Function to send password reset email
export const sendPasswordResetEmail = async (email) => {
    const status = await firebase.auth().sendPasswordResetEmail(email)
    .then(() => {
        return "success";
    })
    .catch((error) => {
        return error
    });
    return status
}

// Function to check action code validity
export const checkActionCode = async (code) => {
    const status = await firebase.auth().checkActionCode(code)
    .then((info) => {
        return info;
    })
    .catch((error) => {
        return error
    });
    return status;
}

// Function to apply action code validity
export const applyActionCode = async (code) => {
    const status = await firebase.auth().applyActionCode(code)
    .then(() => {
        return true;
    })
    .catch((error) => {
        return error
    });
    return status;
}


// Function to check password reset code validity
export const verifyPasswordResetCode = async (code) => {
    const status = await firebase.auth().verifyPasswordResetCode(code)
    .then(() => {
        return true;
    })
    .catch((error) => {
        return error
    });
    return status;
}

// Function to confirm password reset
export const confirmPasswordReset = async (code, password) => {
    const status = await firebase.auth().confirmPasswordReset(code, password)
    .then(() => {
        return true;
    })
    .catch((error) => {
        return error
    });
    return status;
}

/* ------------------------- FIRESTORE ------------------------- */

// Function to create a user in users table
export const createUser = async (uid, data) => {
    const status = await usersCollection.doc(uid).set(data).then(() => {
        return true
    });
    return status
}


// Function to create an new user order for the new user
export const createUserOrders = async (uid, data) => {
    const status = await userOrdersCollection.doc(uid).set(data).then(() => {
        return true
    });
    return status
}


// Function to create an new user order for the new user
export const createUserInfo = async (uid, data) => {
    const status = await userInfoCollection.doc(uid).set(data).then(() => {
        return true
    });
    return status
}

// Function to check if user exists in firestore
export const checkUserExists = async (uid) => {
    let userExists = false;
    
    await usersCollection.doc(uid).get()
    .then((doc) => {
        if(doc.data()){
            userExists = true
        }
    })
    .catch(() => {
        userExists = false
    });

    return userExists
}